import { createI18n } from 'vue-i18n'
import nb from '@/i18n/nb'
import nn from '@/i18n/nn'

const i18n = createI18n({
  locale: 'nb',
  messages: {
    nb,
    nn,
  },
})

export default i18n

import { createApp } from 'vue'
import { createHead } from '@vueuse/head'

import App from './App.vue'
import router from './router'
import i18n from '@/i18n'
import config from '@/config'
import vClickOutside from 'click-outside-vue3'
import { mdHtml } from '@/core/directives'
import { createGtm } from '@gtm-support/vue-gtm'

const head = createHead()
const bootstrapApp = async () => {
  const tenantConfig = await config()

  createApp(App, {
    tenantConfig,
  })
  .use(head)
  .use(router)
  .use(i18n)
  .use(vClickOutside)
  .use(
    createGtm({
      id: 'GTM-KPLC4RK',
    }),
  )
  .directive('md-html', mdHtml)
  .mount('#app')
}

bootstrapApp()
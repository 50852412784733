export default {
  CURTAIN_ACTION_EO_TITLE: 'Er du sikker på at du vil avbryte?',
  CURTAIN_ACTION_EO_DESCRIPTION:
    'Sven kommer til å savne deg. Arbeidet ditt vil bli lagret.',
  CURTAIN_ACTION_EO_CANCEL: 'Ja',
  CURTAIN_ACTION_EO_SUBMIT: 'Nei',
  CURTAIN_ACTION_SUBMIT: 'Fortsette',
  CURTAIN_ACTION_CANCEL: 'Avbryt',
  CURTAIN_FEEDBACK_TITLE: 'Takk!',
  CURTAIN_FEEDBACK_DESCRIPTION:
    'Nå forstår jeg hvordan vi bruker språklige dempere for å uttrykke høflighet.',
  CURTAIN_FEEDBACK_NEXT: 'Fortsette',
  CURTAIN_FEEDBACK_BACK: 'Tilbake',
  CURTAIN_FEEDBACK_BOX_TITLE: 'Vil du fortsette?',
  CURTAIN_ACTION_RACTIVITY_TITLE: 'Nullstille {title}?',
  CURTAIN_ACTION_RACTIVITY_DESCRIPTION:
    'For at du skal kunne løse oppgavene på nytt, må vi slette svarene dine i Hjem til middag. Ønsker du å nullstille?',
  CURTAIN_ACTION_RACTIVITY_SUBMIT: 'Nullstill oppgaver',
  CURTAIN_LANG_SELECT_TITLE: 'Velg målform',
  CURTAIN_LANG_SELECT_DESCRIPTION:
    'Før du begynner, må du velge målform.<br/>Du kan endre dette senere.',
  CURTAIN_LANG_SELECT_SAVE: 'Lagre',
  CURTAIN_LANG_SWITCH_TITLE: 'Skift målform',
  CURTAIN_LANG_SWITCH_DESCRIPTION: '',
  CURTAIN_LANG_SWITCH_SAVE: 'Lagre',
}

import login from './login/nb'
import dashboard from './dashboard/nb'
import arena from './arena/nb'
import activity from './activity/nb'
import curtain from './curtain/nb'
import card from './card/nb'

const nb = {
  'LOGIN': 'Logg inn',
}

export default {
  ...nb,
  ...login,
  ...dashboard,
  ...arena,
  ...activity,
  ...curtain,
  ...card,
}

export default {
  TOPBAR_LOGOUT: 'Logg ut',
  TOPBAR_MODAL_TEXT: 'Kven er Sven?',
  DASHBOARD_ARENA_HEADER_TEXT: 'Ta med deg Sven og vis han rundt.',
  WELCOME_HI: 'Hei',
  WELCOME_INTRO:
    'Eg heiter Sven. Ingen likar meg. Kanskje det er måten eg snakkar på. Kan du hjelpe meg?',
  SKIP_INTRO: 'Hopp over',
  TOPBAR_LANG_SELECT: 'Skifte målform',
}

import { createRouter, createWebHistory } from 'vue-router'
import { beforeEach } from '@/core/router'

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/Dashboard.vue'),
  },
  {
    path: '/activity/:arena/:activity/:element?',
    name: 'Activity',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/Activity.vue'),
  },
  {
    path: '/arena/:arena',
    name: 'Arena',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/Arena.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
  },{
    path: '/styleguide',
    name: 'Styleguide',
    component: () => import('@/views/Styleguide.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(beforeEach)

export default router

import { computed } from 'vue'

import DB from '@/core/services/db'
import useAuth from '@/core/useAuth'
import useTenant from '@/core/useTenant'

const db = new DB()
const { user } = useAuth()
const { tenant } = useTenant()
const prefix = computed(
  () => `${tenant.value.namespace}-${tenant.value.slug}-${user.value._id}`,
)

const settings = {
  lang: 'lang',
}

const createOrUpdateSetting = async (settingId, value) => {
  try {
    const item = await db.createOrUpdate({
      _id: `${prefix.value}-settings-${settingId}`,
      value,
    })

    return item
  } catch (err) {
    console.error(err)
  }
}

const getSetting = async settingId => {
  try {
    const value = await db.getData(`${prefix.value}-settings-${settingId}`)

    return value
  } catch (err) {
    return null
  }
}

export { settings, getSetting, createOrUpdateSetting }

import login from './login/nn'
import dashboard from './dashboard/nn'
import arena from './arena/nn'
import activity from './activity/nn'
import curtain from './curtain/nn'
import card from './card/nn'

const nn = {
  LOGIN: 'Logg in',
}

export default {
  ...nn,
  ...login,
  ...dashboard,
  ...arena,
  ...activity,
  ...curtain,
  ...card,
}

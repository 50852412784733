const TENANT_URL = window.location.hostname
const hostnameSlug = () => {
  const extractedSlug = TENANT_URL.split('.')[0]
  const hasDigit = new RegExp(/[0-9]/, 'g').test(extractedSlug)

  if (hasDigit) {
    if (extractedSlug.indexOf('-') !== -1) {
      return extractedSlug
    }

    const string = extractedSlug.split(/\d/)

    return extractedSlug.replace(string[0], '$&-')
  }

  return extractedSlug
}

const currentTenant = () => import(`./tenants/${hostnameSlug()}`)

export default async () => (await currentTenant()).default

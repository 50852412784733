<template>
  <router-view />
</template>

<script>
import { onBeforeMount, watch } from 'vue'
import useAuth from '@/core/useAuth'
import { useTenant } from '@/core'
import { getSetting } from '@/services/userSettings'
import { useI18n } from 'vue-i18n'

export default {
  props: {
    tenantConfig: Object,
  },

  setup(props) {
    const { initTenant } = useTenant()
    const { user } = useAuth()
    const { locale } = useI18n()
    onBeforeMount(() => {
      initTenant(props.tenantConfig)
    })

    watch(user, async () => {
      if (user) {
        const userLang = await getSetting('lang')
        if (userLang && userLang.value && locale.value !== userLang.value) {
          locale.value = userLang.value
        }
      }
    })
  },
}
</script>

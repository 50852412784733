export default {
  TOPBAR_LOGOUT: 'Logg ut',
  TOPBAR_MODAL_TEXT: 'Hvem er Sven?',
  DASHBOARD_ARENA_HEADER_TEXT: 'Ta med deg Sven og vis ham rundt.',
  WELCOME_HI: 'Hei',
  WELCOME_INTRO:
    'Jeg heter Sven. Ingen liker meg. Kanskje det er måten jeg snakker på. Kan du hjelpe meg?',
  SKIP_INTRO: 'Hopp over',
  TOPBAR_LANG_SELECT: 'Skifte målform',
}
